import logo from "./logo.webp"
const clientData = {
    client_entity: 23,
    attorney_name: 'Sara Saba Law',
    attorney_number: '877-439-9717',
    attorney_email: 'sara@sabafamilylaw.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_-_sara_saba+(720p).mp4',
    mainColor: '#1F263A',
    secondaryColor: '#417A9F',
    siteLink: '',
    logo
}

export default clientData